import { Core } from '@nsftx/systems-sdk';
import getAppRunUuid from '@/utility/getAppRunUuid';
import { logger } from '@nsoft/chameleon-sdk/src/utility';
import mapStringToBoolean from '@/utility/mapStringToBoolean';
import deviceManagementApi from './deviceManagementApi';
import deviceManagementDisplayService from './deviceManagementDisplayService';

let core = null;
let isConnected = false;

export default {
  run() {
    return new Promise((resolve, reject) => {
      core = new Core('Ticket Preview v1');

      core.connect().catch((err) => {
        logger.error('[deviceManagementCoreService] Core failed to connect', err);
        reject(new Error(err));
      });

      core.on('connection', () => {
        isConnected = true;
        deviceManagementDisplayService
          .initDmDisplay(core)
          .then(() => {
            resolve(core);
          })
          .catch((err) => {
            reject(new Error(err));
          });
      });
    });
  },

  getCore() {
    return new Promise((resolve, reject) => {
      if (!isConnected) {
        logger.error('[deviceManagementCoreService] Core is not initialized.');
        reject(new Error('Core is not initialized.'));
      }
      resolve(core);
    });
  },

  getApplicationConfigUrl() {
    const appRunUuid = getAppRunUuid();
    return deviceManagementDisplayService.getApplication(appRunUuid);
  },

  getApplicationConfig() {
    return new Promise((resolve, reject) => {
      this.getApplicationConfigUrl()
        .then((data) => {
          const parsedData = JSON.parse(data);

          if (!parsedData.config_url) {
            logger.error('[deviceManagementCoreService] Application config url is empty');
            reject(new Error('Application config url is empty'));
            return;
          }

          deviceManagementApi
            .getAppConfigData(parsedData.config_url)
            .then((res) => {
              resolve(res.data);
            });
        })
        .catch((err) => {
          logger.error('[deviceManagementCoreService] Failed to fetch application config url', err);
          reject(new Error('Failed to fetch application config url', err));
        });
    });
  },

  getData() {
    return new Promise((resolve, reject) => {
      this.getCore().then(() => {
        const getDataTimer = setTimeout(() => {
          reject();
        }, 5000);

        return Promise.all([this.getApplicationConfig(), core.deviceState()])
          .then((data) => {
            clearTimeout(getDataTimer);
            const [appConfigData, deviceStateData] = data;

            resolve({
              device: {
                data: {
                  device: {
                    name: deviceStateData.device.name,
                    uuid: deviceStateData.device.uuid,
                    deviceId: deviceStateData.device.device_id,
                  },
                  betshop: {
                    betshopId: deviceStateData.location.betshop_id,
                    companyId: deviceStateData.space.company_id,
                    uuid: deviceStateData.location.uuid,
                    name: deviceStateData.location.name,
                    companyName: deviceStateData.space.name,
                    companyUuid: deviceStateData.space.uuid,
                  },
                },
              },
              appConfig: {
                data: {
                  sevenUrl: appConfigData.seven_url,
                  sevenSocketUrl: appConfigData.seven_socket_url,
                  layout: appConfigData.app_config.layout,
                  betsLayout: appConfigData.app_config.bets_layout,
                  marketingDisabled: !mapStringToBoolean(appConfigData.app_config.marketing_enabled),
                  debugMode: mapStringToBoolean(appConfigData.app_config.debug_enabled),
                },
              },
            });
          })
          .catch(reject);
      });
    });
  },
};

const mapStringToBoolean = (data) => {
  if (data.toLowerCase() === 'true') {
    return true;
  }
  if (data.toLowerCase() === 'false') {
    return false;
  }

  return undefined;
};

export default mapStringToBoolean;
